import React from 'react'
import ViewDropShippingFulfillmentForm from '../../forms/dropShippingFulfillment/ViewDropShippingFulfillmentForm'
import PageTitle from '../../utils/PageTitle'
import PageCard from '../../utils/PageCard'

function DropShippingFulfillment() {
  return (
    <PageCard>
      <PageTitle>Drop Shipping Fulfillment</PageTitle>
      <ViewDropShippingFulfillmentForm />
    </PageCard>
  )
}

export default DropShippingFulfillment
