import React from 'react'
import ViewPaymentMethodForm from '../../forms/paymentMethod/ViewPaymentMethodForm'
import PageTitle from '../../utils/PageTitle'
import PageCard from '../../utils/PageCard'

function PaymentMethod() {
  return (
    <PageCard>
      <PageTitle>Payment Method</PageTitle>
      <ViewPaymentMethodForm />
    </PageCard>
  )
}

export default PaymentMethod
