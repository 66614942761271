export enum VendorTier {
  cirrus = 'cirrus',
  cumulus = 'cumulus',
  stratus = 'stratus',
  nimbus = 'nimbus',
}

export const vendorTierLabels = {
  [VendorTier.cirrus]: 'Cirrus',
  [VendorTier.cumulus]: 'Cumulus',
  [VendorTier.stratus]: 'Stratus',
  [VendorTier.nimbus]: 'Nimbus',
}

export enum VendorContractType {
  commission = 'commission',
  wholesale = 'wholesale',
}

export const vendorContractTypeLabels = {
  [VendorContractType.commission]: 'Commission',
  [VendorContractType.wholesale]: 'Wholesale',
}

export enum VendorStatus {
  active = 'active',
  activeSoldOut = 'activeSoldOut',
  onboarding = 'onboarding',
  offboarded = 'offboarded',
  offboarding = 'offboarding',
  onPause = 'onPause',
  retiring = 'retiring',
  retired = 'retired',
}

export const vendorStatusLabels = {
  [VendorStatus.active]: 'Active',
  [VendorStatus.activeSoldOut]: 'Active - Sold Out',
  [VendorStatus.onboarding]: 'Onboarding',
  [VendorStatus.offboarded]: 'Offboarded',
  [VendorStatus.offboarding]: 'Offboarding',
  [VendorStatus.onPause]: 'On Pause',
  [VendorStatus.retiring]: 'Retiring',
  [VendorStatus.retired]: 'Retired',
}

export enum Pronouns {
  heHim = 'heHim',
  sheHer = 'sheHer',
  theyThem = 'theyThem',
}

export const pronounLabels = {
  [Pronouns.heHim]: 'he / him',
  [Pronouns.sheHer]: 'she / her',
  [Pronouns.theyThem]: 'they / them',
}

export enum DropShippingAllowed {
  allowed = 'allowed',
  notAllowed = 'notAllowed',
}

export const dropShippingAllowedLabels = {
  [DropShippingAllowed.allowed]: '✅',
  [DropShippingAllowed.notAllowed]: '❌',
}

type Address = {
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
  country: string
}

// VENDOR ACCOUNT SETTINGS
export type Vendor = {
  brandName: string
  commissionPercentage: number
  completedVendorEthics: boolean
  contract: string[]
  contractAddendum?: string[]
  contractType?: VendorContractType[]
  dropShippingAllowed: DropShippingAllowed
  email: string
  id: string
  initialPassword: string
  paymentMethod?: string
  skuPrefix: string
  status: VendorStatus
  tier: VendorTier
  uid: string
}

export type VendorSpelling = {
  brandName: string
  id: string
  skuPrefix: string
}

export type VendorRole = {
  uid: string
  vendorId: string
}

// VENDOR PROFILE
export type VendorProfile = {
  actionList: Array<string>
  basedIn: {
    city: string
    country: string
    state: string
  }
  brandDescription: string
  brandProfileImage: string
  contactEmail: string
  contactLocation: {
    city: string
    country: string
  }
  contactPhoneNumber: string
  id: string
  mailingAddress: Address
  pointOfContact: {
    firstName: string
    lastName: string
  }
  pronouns: Array<Pronouns>
  socialMedia: {
    instagram: string
    tiktok: string
    twitter: string
  }
  website: string
}

export type DropShipment = {
  id: string
  costOfShipping: number
  courier: string
  dateShipped: string
  orderNumber: string
  receiptImage: Array<string>
  submissionDate: string
  trackingNumber: string
  trackingUrl: string
  vendorId: string
  vendorName: string
  vendorSkuPrefix: string
  fulfillmentId: string
}

export type Admin = {
  email: string
  uid: string
}

export type ShippingProfile = {
  id: string
  australia: number
  canada: number
  europeFlatRate: number
  france: number
  germany: number
  intnlFlatRate: number
  italy: number
  netherlands: number
  newZealand: number
  southKorea: number
  uk: number
  usa: number
  vendorName: string
  vendorSkuPrefix: string
  vendorId: string
  isAllEurope: boolean
  submissionDate: string
}

export type OnboardingChecklist = {
  completedVendorEthics: boolean
  vendorContractSigned: boolean
  paymentPortalCompleted: boolean
  viewedVendorMediaGuide: boolean
  completedVendorProfile: boolean
  completedShippingProfile: boolean
  viewedProductPhotographyGuide: boolean
  addedFirstProduct: boolean
}

export enum PreferredPaymentMethod {
  ach = 'ach',
  wire = 'wire',
  paypal = 'paypal',
}

export const preferredPaymentMethodLabels = {
  [PreferredPaymentMethod.ach]: 'Domestic ACH',
  [PreferredPaymentMethod.wire]: 'International Wire',
  [PreferredPaymentMethod.paypal]: 'PayPal',
}

export enum BankAccountType {
  checking = 'checking',
  savings = 'savings',
}

export const bankAccountTypeLabels = {
  [BankAccountType.checking]: 'Checking',
  [BankAccountType.savings]: 'Savings',
}

export enum BankAccountOwner {
  personal = 'personal',
  business = 'business',
}

export const bankAccountOwnerLabels = {
  [BankAccountOwner.personal]: 'Personal',
  [BankAccountOwner.business]: 'Business',
}

type ACHPaymentMethod = {
  address: Address
  accountType: BankAccountType
  accountOwner: BankAccountOwner
  routingNumber: string
  accountNumber: string
}

type WirePaymentMethod = {
  address: Address
  bankName: string
  accountType: BankAccountType
  accountOwner: BankAccountOwner
  swiftCode: string
  iban?: string
  accountNumber?: string
  ibanNotRequired: boolean
  purposeCode?: string
  branchCode?: string
}

export type PaymentMethod = {
  id: string
  recipientName: string
  method: PreferredPaymentMethod
  ach?: ACHPaymentMethod
  wire?: WirePaymentMethod
  paypal?: string
  verified: boolean
  vendorId: string
  vendorName: string
  vendorUid: string
}

export type ShopifyFulfillmentShippingAddress = {
  firstName: string
  address1: string
  phone: string
  city: string
  zip: string
  province: string
  country: string
  lastName: string
  address2: null | string
  company: null | string
  latitude: number
  longitude: number
  name: string
  countryCode: string
  provinceCode: string
}

export type ShopifyFulfillment = {
  orderNumber: number
  orderId: number
  shippingAddress: ShopifyFulfillmentShippingAddress
  updatedAt: string
  createdAt: string
  vendorName: string
  id: string
  lineItems: Array<{
    title: string
    quantity: number
    grams: number
  }>
  vendorId: string
  service: string
  trackingCompany: string | null
  trackingNumber: string | null
  trackingUrl: string | null
  status: string
}
