import { Text, Box, DataTable, Anchor, Button } from 'grommet'
import { format } from 'date-fns'
// import { FormNextLink } from 'grommet-icons'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FormNextLink } from 'grommet-icons'
import { selectDropShipments, selectShopifyFulfillments, selectSignedInRole } from '../../../state/selectors/firestore'
import PageTitle from '../../utils/PageTitle'
import { DropShipment, ShopifyFulfillment } from '../../../lib/firestore/types/types'
import PageCard from '../../utils/PageCard'

function DropShippingFulfillments() {
  const role = useSelector(selectSignedInRole)
  const dropShipments: DropShipment[] = useSelector(selectDropShipments)
  const shopifyFulfillments: ShopifyFulfillment[] = useSelector(selectShopifyFulfillments)

  const navigate = useNavigate()

  const ShopifyFulfillmentsTable = (
    <DataTable
      sortable
      columns={[
        ...(role === 'admin'
          ? [
              {
                property: 'vendorName',
                header: 'Vendor',
                search: true,
                sortable: true,
              },
            ]
          : []),
        {
          property: 'orderNumber',
          header: <Text>Order Number</Text>,
          primary: true,
          search: true,
          sortable: true,
        },
        {
          property: 'shippingAddress',
          header: <Text>Customer</Text>,
          search: true,
          sortable: true,
          render: (datum: ShopifyFulfillment) => {
            const customerName = `${datum.shippingAddress.firstName} ${datum.shippingAddress.lastName}`
            return <Text>{customerName}</Text>
          },
        },
        {
          property: 'createdAt',
          header: 'Created',
          render: (datum) => {
            return <Text>{format(new Date(datum.createdAt), 'MM/dd/yyyy')}</Text>
          },
        },
        // {
        //   property: 'updatedAt',
        //   header: 'Updated',
        //   render: (datum) => {
        //     return <Text>{format(new Date(datum.updatedAt), 'MM/dd/yyyy')}</Text>
        //   },
        // },
        ...(role === 'admin'
          ? [
              {
                property: 'View On Shopify',
                header: 'View On Shopify',
                render: (datum) => {
                  return (
                    <Anchor href={`https://admin.shopify.com/store/apocene/orders/${datum.orderId}`} target="_blank">
                      View Order
                    </Anchor>
                  )
                },
              },
            ]
          : []),
        {
          property: 'id',
          header: '',
          render: (datum) => {
            return (
              <Link to={`/dropShippingFulfillments/new/${datum.id}`}>
                <Button secondary label="Fulfill" />
              </Link>
            )
          },
        },
      ]}
      data={shopifyFulfillments}
    />
  )

  const DropShipFulfillmentsTable = (
    <DataTable
      sortable
      onClickRow={(e: any) => {
        navigate(`/dropShippingFulfillments/${e?.datum?.id}`)
      }}
      columns={[
        ...(role === 'admin'
          ? [
              {
                property: 'vendorName',
                header: 'Vendor',
                search: true,
                sortable: true,
              },
            ]
          : []),
        {
          property: 'orderNumber',
          header: <Text>Order Number</Text>,
          primary: true,
          search: true,
          sortable: true,
        },
        {
          property: 'dateShipped',
          header: 'Shipped',
          render: (datum: DropShipment) => {
            return <Text>{format(new Date(datum.dateShipped), 'MM/dd/yyyy')}</Text>
          },
        },
        {
          property: 'submissionDate',
          header: 'Submitted',
          render: (datum: DropShipment) => {
            return <Text>{format(new Date(datum.dateShipped), 'MM/dd/yyyy')}</Text>
          },
        },
        {
          property: 'trackingURL',
          header: 'Tracking Link',
          render: (datum: DropShipment) => {
            if (datum.trackingUrl) {
              return (
                <Box pad={{ vertical: 'xsmall' }}>
                  <Anchor
                    href={datum.trackingUrl}
                    label="Track"
                    target="_blank"
                    icon={<FormNextLink />}
                    reverse
                    onClick={(e) => e.stopPropagation()}
                  />
                </Box>
              )
            }
            return <Text>N/A</Text>
          },
        },
      ]}
      data={dropShipments}
    />
  )
  //
  // const actionButton = (
  //   <Button secondary label="Fulfill Drop Ship Order" onClick={() => navigate('/dropShippingFulfillments/new')} />
  // )

  const dropShipmentHistory = (
    <PageCard>
      <PageTitle>Drop Shipping History</PageTitle>
      {DropShipFulfillmentsTable}
    </PageCard>
  )

  return (
    <>
      <PageCard>
        <PageTitle>Orders Awaiting Fulfillment</PageTitle>
        <Box>{ShopifyFulfillmentsTable}</Box>
      </PageCard>
      {dropShipments.length > 0 && dropShipmentHistory}
    </>
  )
}

export default DropShippingFulfillments
