import { Text } from 'grommet'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import EditVendorProfileForm from '../../../forms/vendorProfile/EditVendorProfileForm'
import { selectVendorsById } from '../../../../state/selectors/firestore'
import PageTitle from '../../../utils/PageTitle'
import PageCard from '../../../utils/PageCard'

function EditVendorProfile() {
  const params = useParams()

  const vendorId = params.id
  if (!vendorId) {
    return null
  }

  const vendorsById = useSelector(selectVendorsById)
  const vendor = vendorsById[vendorId]

  if (!vendor) {
    return <Text>Vendor ID Does not exist</Text>
  }

  return (
    <PageCard>
      <PageTitle>{vendor.brandName}</PageTitle>
      <Text size="xxlarge">Vendor Profile</Text>
      <EditVendorProfileForm />
    </PageCard>
  )
}

export default EditVendorProfile
