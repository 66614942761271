import React from 'react'
import NewShippingProfileForm from '../../forms/shippingProfile/NewShippingProfileForm'
import PageTitle from '../../utils/PageTitle'
import PageCard from '../../utils/PageCard'

function NewShippingProfile() {
  return (
    <PageCard>
      <PageTitle>New Shipping Profile</PageTitle>
      <NewShippingProfileForm />
    </PageCard>
  )
}

export default NewShippingProfile
