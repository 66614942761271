import React from 'react'
import { Box, Text } from 'grommet'
import CurrencyConverter from '../utils/CurrencyConverter'

const help = <Text size="xsmall">Cost of shipping in USD</Text>

export const addShippingRateFormSchema = [
  {
    formContent: (
      <Box>
        <Text margin={{ bottom: 'small' }} weight="bold">
          We'll need you to calculate your item's shipping costs to different regions.{' '}
        </Text>
        <Text margin={{ bottom: 'small' }}>
          The amount you quote us will be set on the site for customers to pay upon ordering. If shipping costs more
          than the amount you quoted, we will not be able to reimburse you for the difference - so please consider that
          when setting the shipping rates with us. We suggest setting shipping slightly higher to avoid you losing money
          on shipping!
        </Text>
        <Text>
          Please quote us on the cost of shipping the pieces out to the countries below, the ones individually listed
          are based on where our largest customer bases are located
        </Text>
      </Box>
    ),
  },
  {
    formContent: <CurrencyConverter />,
  },
  {
    id: 'usa',
    name: 'United States',
    type: 'currency',
    required: true,
    help,
  },
  {
    id: 'uk',
    name: 'United Kingdom',
    type: 'currency',
    required: true,
    help,
  },
  {
    id: 'australia',
    name: 'Australia',
    type: 'currency',
    required: true,
    help,
  },
  {
    id: 'canada',
    name: 'Canada',
    type: 'currency',
    required: true,
    help,
  },
  {
    id: 'isAllEurope',
    type: 'checkbox',
    name: 'Quote Shipping for All of Europe',
    label: 'Quote shipping for all of Europe instead of individual countries',
  },
  {
    id: 'europeFlatRate',
    type: 'currency',
    name: 'All of Europe',
    required: true,
    help,
    condition: (formState: any) => formState.isAllEurope,
  },
  {
    id: 'germany',
    name: 'Germany',
    type: 'currency',
    required: true,
    help,
    condition: (formState: any) => !formState.isAllEurope,
  },
  {
    id: 'france',
    name: 'France',
    type: 'currency',
    required: true,
    help,
    condition: (formState: any) => !formState.isAllEurope,
  },
  {
    id: 'italy',
    name: 'Italy',
    type: 'currency',
    required: true,
    help,
    condition: (formState: any) => !formState.isAllEurope,
  },
  {
    id: 'netherlands',
    name: 'Netherlands',
    type: 'currency',
    required: true,
    help,
    condition: (formState: any) => !formState.isAllEurope,
  },
  {
    id: 'newZealand',
    name: 'New Zealand',
    type: 'currency',
    required: true,
    help,
  },
  {
    id: 'southKorea',
    name: 'South Korea',
    type: 'currency',
    required: true,
    help,
  },
  {
    id: 'intnlFlatRate',
    name: 'Rest of the World',
    type: 'currency',
    required: true,
    help,
  },
]
