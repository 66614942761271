import React from 'react'
import ViewShippingProfileForm from '../../forms/shippingProfile/ViewShippingProfileForm'
import PageTitle from '../../utils/PageTitle'
import PageCard from '../../utils/PageCard'

function ShippingProfile() {
  return (
    <PageCard>
      <PageTitle>Shipping Profile</PageTitle>
      <ViewShippingProfileForm />
    </PageCard>
  )
}

export default ShippingProfile
