import { Box, Text } from 'grommet'
import { Country, State, City } from 'country-state-city'
import _ from 'lodash'
import React from 'react'
import { pronounLabels, Pronouns } from '../../../lib/firestore/types/types'
import { mapEnumAndLabelsToOptions } from '../autoform/utils'

const countries = Country.getAllCountries()
const states = State.getAllStates()
const cities = City.getAllCities()
const countryOptions = _.uniq(_.map(countries, 'name'))
const stateOptions = _.uniq(_.map(states, 'name'))
const cityOptions = _.uniq(_.map(cities, 'name'))

// const allCountryCallingCodes = _.map(countryPhoneCodes.all(), 'countryCallingCode')
// const initialCountryCodeOptions = countryPhoneCodes.all().map((country) => ({
//   value: country.countryCallingCode,
//   label: `(+${country.countryCallingCode})`,
// }))
// const cleanedCountryCodeOptions = _.sortBy(_.uniqBy(initialCountryCodeOptions, 'value'), (datum) => Number(datum.value))

const website = {
  id: 'website',
  name: 'Website',
  type: 'url',
  required: false,
}

const socialMedia = {
  id: 'socialMedia',
  name: 'Social Media',
  type: 'compound',
  fields: [
    {
      id: 'instagram',
      name: 'Instagram',
      type: 'text',
      required: true,
    },
    {
      id: 'tiktok',
      name: 'TikTok',
      type: 'text',
      required: false,
    },
    {
      id: 'twitter',
      name: 'Twitter',
      type: 'text',
      required: false,
    },
  ],
}

const basedIn = {
  id: 'basedIn',
  name: 'We are based in...',
  type: 'compound',
  fields: [
    {
      id: 'city',
      name: 'City',
      required: false,
      type: 'select',
      search: true,
      options: cityOptions,
    },
    {
      id: 'state',
      name: 'State',
      required: false,
      type: 'select',
      search: true,
      options: stateOptions,
    },
    {
      id: 'country',
      name: 'Country',
      required: false,
      type: 'select',
      search: true,
      options: countryOptions,
    },
  ],
}

const mailingAddress = {
  id: 'mailingAddress',
  name: 'Mailing Address',
  type: 'address',
}

const brandProfileImage = {
  id: 'brandProfileImage',
  name: 'Brand Logo or Designer Profile Image',
  type: 'images',
  required: true,
}

const brandDescription = {
  id: 'brandDescription',
  name: 'Brand Description / Designer Bio',
  type: 'textarea',
  required: true,
}

const pronouns = {
  id: 'pronouns',
  name: "Designer's Pronouns",
  type: 'checkboxgroup',
  options: mapEnumAndLabelsToOptions(Pronouns, pronounLabels),
  required: true,
  readOnlyValueFormatter: (valArray: Array<keyof typeof Pronouns>) => {
    if (!valArray) return ''
    return valArray.map((val: keyof typeof Pronouns): any => Pronouns[val])
  },
}

const pointOfContact = {
  id: 'pointOfContact',
  name: 'Point of Contact',
  type: 'compound',
  fields: [
    {
      id: 'firstName',
      required: true,
      name: 'First Name',
      type: 'text',
    },
    {
      id: 'lastName',
      required: true,
      name: 'Last Name',
      type: 'text',
    },
  ],
}

const contactLocation = {
  id: 'contactLocation',
  name: 'Contact Location',
  type: 'compound',
  fields: [
    {
      id: 'city',
      name: 'City',
      required: false,
      type: 'select',
      search: true,
      options: cityOptions,
    },
    {
      id: 'state',
      name: 'State',
      required: false,
      type: 'select',
      search: true,
      options: stateOptions,
    },
    {
      id: 'country',
      name: 'Country',
      required: false,
      type: 'select',
      search: true,
      options: countryOptions,
    },
  ],
}

const contactEmail = {
  id: 'contactEmail',
  name: 'Contact Email',
  type: 'text',
  validate: (value: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (emailRegex.test(value)) {
      return ''
    }

    return 'Please enter a valid email address'
  },
  required: true,
}

const contactPhoneNumber = {
  id: 'contactPhoneNumber',
  name: 'Contact Phone Number',
  type: 'compound',
  fieldsAlignment: 'start',
  fields: [
    {
      id: 'countryCode',
      name: 'Country Code',
      type: 'number',
      search: true,
      // options: cleanedCountryCodeOptions,
      width: '140px',
      // validate: (value: number) => {
      //   if (allCountryCallingCodes.includes(value)) {
      //     return ''
      //   }
      //   return 'Please enter a valid country code'
      // },
      required: true,
    },
    {
      id: 'phoneNumber',
      type: 'number',
      name: 'Phone Number',
      required: true,
    },
  ],
}

export const editVendorProfileFormSchema = [
  website,
  socialMedia,
  basedIn,
  mailingAddress,
  brandProfileImage,
  {
    formContent: (
      <Box margin={{ vertical: 'medium' }}>
        <Text weight="bold">Formatting Instructions</Text>
        <Text>
          Please fill out this form with proper grammar and capitalizations <br />
          Please write the <i>Brand Description / Designer Bio</i> in the third-person <br />
        </Text>
      </Box>
    ),
  },
  brandDescription,
  pronouns,
  pointOfContact,
  contactLocation,
  contactEmail,
  contactPhoneNumber,
]
