import { parsePhoneNumberFromString, getCountryCallingCode, CountryCode } from 'libphonenumber-js'
import { ShopifyFulfillment, ShopifyFulfillmentShippingAddress } from '../../lib/firestore/types/types'

export function formatPhoneNumber(shippingAddress: ShopifyFulfillmentShippingAddress) {
  const { phone, countryCode } = shippingAddress

  if (!phone) return ''

  // Check if the phone number already starts with a "+"
  if (phone.startsWith('+')) {
    // Already has a country code, return as is
    const parsePhone = parsePhoneNumberFromString(phone)
    return parsePhone ? parsePhone.formatInternational() : phone
  }

  // Get the country calling code (e.g., +61 for Australia)
  const countryCallingCode = `+${getCountryCallingCode(countryCode as CountryCode)}`

  // Parse the phone number with the country code
  const phoneNumber = parsePhoneNumberFromString(`${countryCallingCode}${phone}`, countryCode as CountryCode)

  // Format the phone number in international format
  return phoneNumber ? phoneNumber.formatInternational() : phone
}

export function formatShopifyFulfillment(fulfillment: ShopifyFulfillment) {
  const newPhoneNumber = formatPhoneNumber(fulfillment.shippingAddress)
  const newFulfillment = {
    ...fulfillment,
    shippingAddress: {
      ...fulfillment.shippingAddress,
      phone: newPhoneNumber,
    },
  }
  return newFulfillment
}
