import { Box } from 'grommet'
import { ReactNode } from 'react'
import styled from 'styled-components'

const PageBox = styled(Box)`
  max-width: 1080px;
  min-width: 540px;
`

interface PageCardProps {
  children: ReactNode
}

function PageCard({ children }: PageCardProps) {
  return (
    <PageBox background="white" round pad="medium" elevation="small" margin={{ bottom: 'medium' }}>
      {children}
    </PageBox>
  )
}

export default PageCard
