import _ from 'lodash'
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore'
// import { getDatabase } from "firebase/database";

import { db } from '../../firebase'
import { Collection } from './collections'

function subscribeToCollection(collectionName: string, callback: any) {
  const collectionQuery = collection(db, collectionName)
  const unsub = onSnapshot(collectionQuery, (querySnapshot: any) => {
    const documents: any = querySnapshot.docs.map((document: any) => {
      return {
        ...document.data(),
        id: document.id,
      }
    })
    callback(documents)
  })
  return unsub
}

function mapQueryArrayToWheres(queryArrayArray: Array<any>) {
  if (!_.isArray(_.first(queryArrayArray))) {
    const [queryLeft, queryOperator, queryRight]: any = queryArrayArray
    return [where(queryLeft, queryOperator, queryRight)]
  }
  return queryArrayArray.map((queryArray) => {
    const [queryLeft, queryOperator, queryRight]: any = queryArray
    return where(queryLeft, queryOperator, queryRight)
  })
}

function subscribeToCollectionWithQuery(collectionName: Collection, queryArray: Array<Array<any>>, callback: any) {
  const collectionQuery = query(collection(db, collectionName), ...mapQueryArrayToWheres(queryArray))
  const unsub = onSnapshot(collectionQuery, (querySnapshot: any) => {
    const documents: any = querySnapshot.docs.map((document: any) => {
      return {
        ...document.data(),
        id: document.id,
      }
    })
    callback(documents)
  })
  return unsub
}

function subscribeToDocument(collectionName: Collection, docId, callback: any) {
  const collectionQuery = doc(db, collectionName, docId)
  const unsub = onSnapshot(collectionQuery, (document: any) => {
    return callback({
      ...document.data(),
      id: document.id,
    })
  })
  return unsub
}

export { subscribeToCollection, subscribeToCollectionWithQuery, subscribeToDocument }
