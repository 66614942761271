import { Box, Header, Text } from 'grommet'
import { Close, Menu } from 'grommet-icons'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import SignOutButton from './authorization/SignOutButton'
import { isVendorMode } from '../utils'
import { selectSignedInVendor } from '../state/selectors/firestore'
import { isViewCollapsed } from './utils/hooks'
import { selectIsSidebarOpen, setIsSidebarOpen } from '../state/reducers/ui'

const StyledMenu = styled(Menu)`
  vertical-align: bottom;
  margin-right: 10px;
  cursor: pointer;
`

const StyledCancel = styled(Close)`
  vertical-align: bottom;
  margin-right: 10px;
  cursor: pointer;
`

const LeftHeaderBox = styled(Box)`
  align-items: center;
`

const HeaderText = styled(Text)`
  cursor: pointer;
`

function PageHeader() {
  const signedInVendor = useSelector(selectSignedInVendor)
  const isSidebarOpen = useSelector(selectIsSidebarOpen, shallowEqual)
  const isCollapsed = isViewCollapsed()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  React.useEffect(() => {
    dispatch(setIsSidebarOpen(!isCollapsed))
  }, [isCollapsed])

  const MenuIcon = isSidebarOpen ? (
    <StyledCancel onClick={() => dispatch(setIsSidebarOpen(!isSidebarOpen))} />
  ) : (
    <StyledMenu onClick={() => dispatch(setIsSidebarOpen(!isSidebarOpen))} />
  )

  return (
    <Header
      border={{ side: 'bottom', color: 'light-4' }}
      background={{ color: 'white' }}
      pad={isCollapsed ? { vertical: 'small', right: 'small', left: 'medium' } : 'medium'}
    >
      <LeftHeaderBox direction="row" alignContent="center">
        {isCollapsed && MenuIcon}
        <HeaderText size={isCollapsed ? 'small' : 'xlarge'} textAlign="center" onClick={() => navigate('/')}>
          {isVendorMode ? 'ap0cene vendor portal' : ''}
          {!isVendorMode ? 'ap0cene admin' : ''}
        </HeaderText>
        {!isCollapsed && (
          <Text size="medium" textAlign="center" color="dark-4" margin={{ left: 'small' }}>
            {isVendorMode ? signedInVendor && signedInVendor.brandName : ''}
          </Text>
        )}
      </LeftHeaderBox>
      <SignOutButton />
    </Header>
  )
}

export default PageHeader
