import React from 'react'
import NewPaymentMethodForm from '../../forms/paymentMethod/NewPaymentMethodForm'
import PageTitle from '../../utils/PageTitle'
import PageCard from '../../utils/PageCard'

function NewPaymentMethod() {
  return (
    <PageCard>
      <PageTitle>New Payment Method</PageTitle>
      <NewPaymentMethodForm />
    </PageCard>
  )
}

export default NewPaymentMethod
