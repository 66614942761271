import { Box, Text } from 'grommet'
import { ShopifyFulfillment } from '../../../lib/firestore/types/types'

type ReadOnlyFulfillmentRequestProps = {
  fulfillmentRequest: ShopifyFulfillment
}

function AddressDisplay({ shippingAddress }: any) {
  const { firstName, lastName, address1, address2, city, province, zip, country, company, phone } = shippingAddress

  return (
    <Box>
      {company && <Text>{company}</Text>}
      <Text>
        {firstName} {lastName}
      </Text>
      <Text>{address1}</Text>
      {address2 && <Text>{address2}</Text>}
      <Text>
        {city}, {province}
      </Text>
      <Text>{zip}</Text>
      <Text>{country}</Text>
      <Text margin={{ top: 'small' }}>Phone: {phone}</Text>
    </Box>
  )
}

function LineItemsDisplay({ lineItems }: any) {
  return (
    <Box>
      {lineItems.map((item, index) => (
        <Box key={index} pad={{ bottom: 'small' }}>
          <Text>{item.title}</Text>
          <Text>Quantity: {item.quantity}</Text>
          <Text>Weight (grams): {item.grams}</Text>
        </Box>
      ))}
    </Box>
  )
}

function ReadOnlyFulfillmentRequest(props: ReadOnlyFulfillmentRequestProps) {
  const { fulfillmentRequest } = props
  const margin = { top: 'medium' }

  return (
    <Box margin={{ horizontal: 'small' }}>
      <Text size="large" weight={500} margin={margin}>
        Order ID
      </Text>
      <Text>{fulfillmentRequest.orderNumber}</Text>
      <Text size="large" weight={500} margin={margin}>
        Items to Fulfill
      </Text>
      <LineItemsDisplay lineItems={fulfillmentRequest.lineItems} />
      <Text size="large" weight={500} margin={margin}>
        Shipping Information
      </Text>
      <AddressDisplay shippingAddress={fulfillmentRequest.shippingAddress} />
    </Box>
  )
}

export default ReadOnlyFulfillmentRequest
