import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Box } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { updateDoc, doc, collection } from 'firebase/firestore'
// import _ from 'lodash'
import { db } from '../../../firebase'
import { Collection } from '../../../lib/firestore/collections'
import { selectSignedInVendor } from '../../../state/selectors/firestore'
import PageTitle from '../../utils/PageTitle'
import AutoForm from '../autoform/AutoForm'
import { initialValuesVendorEthicsForm, vendorEthicsFormSchema } from './constants'
import PageCard from '../../utils/PageCard'
// import joi from '../../../lib/firestore/joi'
// import { Vendor } from '../../../lib/firestore/types'

function ViewVendorEthicsForm() {
  const signedInVendor = useSelector(selectSignedInVendor, shallowEqual)
  const vendorId: any = signedInVendor?.id
  const navigate = useNavigate()
  const currentValueVendorEthicsForm =
    signedInVendor?.completedVendorEthics === true ? { vendorEthics: true } : initialValuesVendorEthicsForm

  const onSubmit = async () => {
    // if we use an updateDoc, would we even need TS or joi validation?
    try {
      await updateDoc(doc(collection(db, Collection.vendors), vendorId), { completedVendorEthics: true })
      navigate(`/onboardingChecklists/${vendorId}`)
    } catch (error) {
      console.error(
        `Error updating document for vendor ID ${vendorId} while setting completedVendorEthics to true ${vendorId}: `,
        error,
      )
    }
  }

  return (
    <PageCard>
      <PageTitle>Vendor Ethics</PageTitle>
      <Box>
        <iframe
          src="https://storage.googleapis.com/marketplace-353300.appspot.com/images/7c9c8118e682359f6c4c79dbe33ace6edcf255fe.pdf"
          title="PDF Viewer"
          style={{
            border: 'none',
            width: '100%',
            height: '100vh',
            zoom: '90%',
          }}
        />
      </Box>
      <AutoForm
        initialValues={currentValueVendorEthicsForm}
        formSchema={vendorEthicsFormSchema}
        readOnlyMode={false}
        onSubmit={onSubmit}
        onCancel={() => navigate(`/onboardingChecklists/${vendorId}`)}
      />
    </PageCard>
  )
}

export default ViewVendorEthicsForm
