// Other code such as selectors can use the imported `RootState` type
import _ from 'lodash'
import { createSelector } from '@reduxjs/toolkit'
// import { format } from 'date-fns'
import { RootState } from '../store'
import { Admin, Vendor, VendorProfile, VendorRole, VendorStatus } from '../../lib/firestore/types/types'
import { selectSignedInUID } from '../reducers/firebaseAuth'
import { formatShopifyFulfillment } from './selectorUtils'

export const selectFirestore = (state: RootState) => state.firestore

// ADMINS
export const selectAdminsById = createSelector(selectFirestore, (firestore) => firestore.admins)
export const selectAdmins = createSelector(selectAdminsById, (admins) => Object.values(admins))

// DROP SHIPMENTS
export const selectDropShipmentsById = createSelector(selectFirestore, (firestore) => {
  return _.mapValues(firestore.dropShipments, (dropShipment) => {
    return dropShipment
    // THIS IS COMMENTED OUT IN CASE I EVER WANT TO BRING BACK DATE FORMATTING AT THE SELECTOR LEVEL
    // return {
    //   ...dropShipment,
    //   dateShipped: format(new Date(dropShipment.dateShipped), 'MM/dd/yyyy'),
    //   submissionDate: format(new Date(dropShipment.submissionDate), 'MM/dd/yyyy'),
    // }
  })
})
export const selectDropShipments = createSelector(selectDropShipmentsById, (dropShipments) =>
  Object.values(dropShipments),
)

export const selectDropShipmentFulfillmentIds = createSelector(selectDropShipments, (dropShipments) =>
  dropShipments.map(({ fulfillmentId }) => fulfillmentId),
)

// VENDORS
export const selectVendorsById = createSelector(selectFirestore, (firestore) => firestore.vendors)
export const selectVendors = createSelector(selectVendorsById, (vendors) => Object.values(vendors))
export const selectActiveVendors = createSelector(selectVendors, (vendors) =>
  vendors.filter((vendor) => vendor.status !== VendorStatus.offboarded),
)

// VENDOR SPELLINGS
export const selectVendorSpellingsById = createSelector(selectFirestore, (firestore) => firestore.vendorSpellings)
export const selectVendorSpellings = createSelector(selectVendorSpellingsById, (vendorSpellings) =>
  Object.values(vendorSpellings),
)

// VENDOR ROLES
export const selectVendorRolesById = createSelector(selectFirestore, (firestore) => firestore.vendorRoles)
export const selectVendorRoles = createSelector(selectVendorRolesById, (vendorRoles) => Object.values(vendorRoles))
export const selectVendorRole = createSelector(
  selectVendorRoles,
  selectSignedInUID,
  (vendorRoles, uid): VendorRole | undefined => {
    return (vendorRoles || []).find((vendorRole: VendorRole) => vendorRole.uid === uid)
  },
)

// VENDOR PROFILES
export const selectVendorProfilesById = createSelector(selectFirestore, (firestore) => firestore.vendorProfiles)
export const selectVendorProfiles = createSelector(selectVendorProfilesById, (vendorProfiles) =>
  Object.values(vendorProfiles),
)
export const selectVendorProfile = createSelector(
  selectVendorProfiles,
  selectVendorRole,
  (vendorProfiles, vendorRole): VendorProfile | undefined => {
    return vendorProfiles.find((vendorProfile: VendorProfile) => vendorProfile.id === vendorRole?.vendorId)
  },
)

// VENDOR SHIPPING RATES
export const selectShippingProfilesById = createSelector(selectFirestore, (firestore) => firestore.shippingProfiles)
export const selectShippingProfiles = createSelector(selectShippingProfilesById, (vendorShippingProfiles) =>
  Object.values(vendorShippingProfiles),
)

// ROLES
export const selectSignedInRole = createSelector(
  selectSignedInUID,
  selectAdmins,
  selectVendorRoles,
  (uid, admins, vendorRoles) => {
    if (admins.find((admin: Admin) => admin.uid === uid)) {
      return 'admin'
    }
    if (vendorRoles.find((vendorRole: VendorRole) => vendorRole.uid === uid)) {
      return 'vendor'
    }
    return ''
  },
)

export const selectSignedInVendor = createSelector(
  selectSignedInUID,
  selectVendors,
  (uid, vendors): Vendor | undefined => {
    return vendors.find((vendor: Vendor) => vendor.uid === uid)
  },
)

// PAYMENT METHODS

export const selectPaymentMethodsById = createSelector(selectFirestore, (firestore) => firestore.paymentMethods)

export const selectPaymentMethods = createSelector(selectPaymentMethodsById, (paymentMethods) =>
  Object.values(paymentMethods),
)

// Products
export const selectProductsById = createSelector(selectFirestore, (firestore) =>
  _.mapValues(firestore.products, (product) => ({
    ...product,
    vendorName: firestore.vendors[product.vendorId]?.brandName,
    vendorSkuPrefix: firestore.vendors[product.vendorId]?.brandName,
  })),
)

export const selectProducts = createSelector(selectProductsById, (products) => Object.values(products))

// SHOPIFY FULFILLMENTS

export const selectShopifyFulfillmentsById = createSelector(
  selectFirestore,
  selectDropShipmentFulfillmentIds,
  (firestore, dropShipmentFulfillmentIds) => {
    const formattedFulfillments = _.mapValues(firestore.shopifyFulfillments, formatShopifyFulfillment)
    return _.pickBy(formattedFulfillments, (shopifyFulfillment) => {
      return !_.includes(dropShipmentFulfillmentIds, shopifyFulfillment.id)
    })
  },
)

export const selectShopifyFulfillments = createSelector(selectShopifyFulfillmentsById, (shopifyFulfillments) =>
  Object.values(shopifyFulfillments),
)

// CHECKLISTS

export const selectOnboardingChecklists = createSelector(
  selectVendors,
  selectPaymentMethods,
  selectVendorProfile,
  selectShippingProfiles,
  (vendors, paymentMethods, vendorProfile, shippingProfiles) => {
    return vendors.map((vendor: Vendor) => {
      const completedVendorEthics = !!vendor.completedVendorEthics
      const completedVendorContract = !!(vendor.contract && vendor.contract.length > 0)
      const hasPaymentMethod = !!paymentMethods.find((paymentMethod) => paymentMethod.vendorId === vendor.id)
      const completedVendorProfileImage = !!vendorProfile?.brandProfileImage
      const completedVendorProfile = !!vendorProfile?.brandDescription
      const completedShippingProfile = !!shippingProfiles.find(
        (shippingProfile) => shippingProfile.vendorId === vendor.id,
      )
      let vendorStatus = ''
      if (!completedVendorEthics || !completedVendorContract) {
        vendorStatus = 'Step 1'
      } else if (!hasPaymentMethod) {
        vendorStatus = 'Step 2'
      } else if (!completedVendorProfileImage || !completedVendorProfile || !completedShippingProfile) {
        vendorStatus = 'Step 3'
      } else {
        vendorStatus = 'Step 4'
      } // update to step 5 when product is ready
      return {
        id: vendor.id,
        vendorName: vendor.brandName,
        skuPrefix: vendor.skuPrefix,
        vendorStatus,
        completedVendorEthics,
        completedVendorContract,
        hasPaymentMethod,
        completedVendorProfileImage,
        completedVendorProfile,
        completedShippingProfile,
      }
    })
  },
)
