import React from 'react'
import { Grommet } from 'grommet'
import { Subtract, Add } from 'grommet-icons'
import { hpe } from 'grommet-theme-hpe'
import { deepMerge } from 'grommet/utils'
import PageHeader from './components/PageHeader'
import { SignInGate } from './components/authorization/SignInGate'
import { SignInScreen } from './components/authorization/SignInScreen'
import NavBody from './components/NavBody'

const customTheme = {
  global: {
    // colors: {
    //   brand: '#ABF5C7',
    // },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
  // button: {
  //   primary: {
  //     background: {
  //       color: 'brand',
  //     },
  //   },
  // },
  accordion: {
    heading: {
      margin: { vertical: '20px', horizontal: '24px' },
    },
    icons: {
      collapse: Subtract,
      expand: Add,
    },
    border: undefined,
  },
}

const theme = deepMerge(hpe, customTheme)

function App() {
  return (
    <Grommet theme={theme}>
      {/* <Box background="light-3"> */}
      <PageHeader />
      <SignInGate signedInContent={<NavBody />} signedOutContent={<SignInScreen />} />
      {/* </Box> */}
    </Grommet>
  )
}

export default App
