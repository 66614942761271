import { Text, DataTable, Button } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { AddButton } from '../ViewComponents'
import { format } from 'date-fns'
import { selectShippingProfiles } from '../../../state/selectors/firestore'
import PageTitle from '../../utils/PageTitle'
import PageCard from '../../utils/PageCard'
import { ShippingProfile } from '../../../lib/firestore/types/types'

function ShippingProfiles() {
  const shippingProfiles = useSelector(selectShippingProfiles)

  const navigate = useNavigate()

  const ShippingProfilesTable = (
    <DataTable
      sortable
      onClickRow={(e: any) => {
        navigate(`/shippingProfiles/${e?.datum?.id}`)
      }}
      columns={[
        {
          property: 'vendorName',
          header: <Text weight="bold">Brand Name</Text>,
          primary: true,
          search: true,
          sortable: true,
        },
        {
          property: 'submissionDate',
          header: 'Submission Date',
          render: (datum: ShippingProfile) => {
            return <Text>{format(new Date(datum.submissionDate), 'MM/dd/yyyy')}</Text>
          },
          sortable: true,
        },
        {
          property: 'vendorSkuPrefix',
          header: 'SKU',
          search: true,
          sortable: true,
        },
      ]}
      data={shippingProfiles}
    />
  )

  const actionButton = (
    <Button secondary label="Add Shipping Profile" onClick={() => navigate('/shippingProfiles/new')} />
  )

  return (
    <PageCard>
      <PageTitle actionButton={actionButton}>Shipping Profiles</PageTitle>
      {ShippingProfilesTable}
    </PageCard>
  )
}

export default ShippingProfiles
