import React from 'react'
import NewDropShippingFulfillmentForm from '../../forms/dropShippingFulfillment/NewDropShippingFulfillmentForm'
import PageTitle from '../../utils/PageTitle'
import PageCard from '../../utils/PageCard'

function NewDropShippingFulfillment() {
  return (
    <PageCard>
      <PageTitle>New Drop Shipping Fulfillment</PageTitle>
      <NewDropShippingFulfillmentForm />
    </PageCard>
  )
}

export default NewDropShippingFulfillment
