import React from 'react'
import ViewOnboardingChecklistForm from '../../forms/onboardingChecklist/ViewOnboardingChecklistForm'
import PageTitle from '../../utils/PageTitle'
import PageCard from '../../utils/PageCard'

function OnboardingChecklist() {
  return (
    <PageCard>
      <PageTitle>Onboarding Checklist</PageTitle>
      <ViewOnboardingChecklistForm />
    </PageCard>
  )
}

export default OnboardingChecklist
