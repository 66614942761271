import { Text, Box, DataTable, Button } from 'grommet'
// import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectVendorSpellings } from '../../../state/selectors/firestore'
import PageTitle from '../../utils/PageTitle'
import PageCard from '../../utils/PageCard'

const TableBox = styled(Box)`
  width: 100%;
  overflow-x: auto;
`

function Spellings() {
  const vendorSpellings = useSelector(selectVendorSpellings)
  // const navigate = useNavigate()

  const VendorTable = (
    <DataTable
      sortable
      onClickRow={(e: any) => {
        console.log('e', e)
        // navigate(`/vendors/${e?.datum?.id}`)
      }}
      columns={[
        {
          property: 'brandName',
          header: <Text weight="bold">Brand Name</Text>,
          search: true,
          sortable: true,
        },
        {
          property: 'id',
          header: <Text weight="bold">ID</Text>,
          search: true,
          sortable: true,
        },
        {
          property: 'skuPrefix',
          header: 'SKU Prefix',
          search: true,
          sortable: true,
        },
      ]}
      data={vendorSpellings}
    />
  )
  console.log(vendorSpellings)
  const actionButton = (
    <Button
      secondary
      label="Add Vendor Spelling"
      // onClick={() => navigate('/vendorAccounts/new')}
    />
  )

  return (
    <PageCard>
      <PageTitle actionButton={actionButton}>Vendor Spellings</PageTitle>
      <TableBox>{VendorTable}</TableBox>
    </PageCard>
  )
}

export default Spellings
