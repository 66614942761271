import _ from 'lodash'
import React from 'react'
import { Box, Text, Collapsible, Sidebar } from 'grommet'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useOnclickOutside from 'react-cool-onclickoutside'
import { isVendorMode } from '../utils'
import { selectShopifyFulfillments, selectSignedInVendor } from '../state/selectors/firestore'
import { selectIsSidebarOpen, setIsSidebarOpen } from '../state/reducers/ui'
import { isViewCollapsed } from './utils/hooks'

const NavContainer = styled(Sidebar)<{ isCollapsed: boolean }>`
  max-width: 300px;
  //border: 1px solid black;
  left: ${({ isCollapsed }) => (isCollapsed ? 0 : 'inherit')};
  position: ${({ isCollapsed }) => (isCollapsed ? 'absolute' : 'inherit')};
  z-index: 10;
  min-height: 100vh;
  height: 100%;
`

const NavItem = styled(Box)`
  //&:hover {
  //  background-color: #dddddd;
  //}
  text-decoration: none;
  box-shadow: none !important;
`

function LeftNav() {
  const location = useLocation()
  const signedInVendor = useSelector(selectSignedInVendor, shallowEqual)
  const isSidebarOpen: boolean = useSelector(selectIsSidebarOpen, shallowEqual)
  const isCollapsed = isViewCollapsed()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const shopifyFulfillments = useSelector(selectShopifyFulfillments, shallowEqual)
  const shopifyFullfillmentCount = shopifyFulfillments?.length ? `(${shopifyFulfillments.length})` : ''
  const dropShippingFulfillmentLabel = `Drop Shipments ${shopifyFullfillmentCount}`

  const vendorPages = _.compact([
    {
      path: `vendors/${signedInVendor?.id}`,
      label: 'Account',
    },
    {
      path: 'products',
      label: 'Products',
    },
    signedInVendor?.dropShippingAllowed && {
      path: 'dropShippingFulfillments',
      label: dropShippingFulfillmentLabel,
    },
    signedInVendor?.dropShippingAllowed && {
      path: 'shippingProfiles',
      label: 'Shipping Profiles',
    },
    {
      path: 'paymentMethods',
      label: 'Payment Methods',
    },
    {
      path: `onboardingChecklists/${signedInVendor?.id}`,
      label: 'Onboarding Checklist',
    },
  ])

  const adminPages = [
    {
      path: 'vendors',
      label: 'Vendors',
    },
    {
      path: 'products',
      label: 'Products',
    },
    {
      path: 'dropShippingFulfillments',
      label: dropShippingFulfillmentLabel,
    },
    {
      path: 'shippingProfiles',
      label: 'Shipping Profiles',
    },
    {
      path: 'paymentMethods',
      label: 'Payment Methods',
    },
    {
      path: 'onboardingChecklists',
      label: 'Onboarding Checklists',
    },
    {
      path: 'spellings',
      label: 'Vendor Spellings',
    },
  ]

  const pages = isVendorMode ? vendorPages : adminPages

  const NavItems = pages.map(({ label, path }) => {
    return (
      <NavItem
        pad={{
          vertical: 'small',
          horizontal: 'medium',
        }}
        round={{ size: 'xsmall' }}
        key={`nav-${path}`}
        onClick={() => {
          navigate(`/${path}`)
          if (isCollapsed) {
            dispatch(setIsSidebarOpen(false))
          }
        }}
        hoverIndicator
        background={location.pathname.split('/')[1] === path.split('/')[0] ? 'light-3' : 'white'}
      >
        <Text>{label}</Text>
      </NavItem>
    )
  })

  const ref = useOnclickOutside(() => {
    if (isCollapsed) {
      dispatch(setIsSidebarOpen(false))
    }
  })

  return (
    <Collapsible direction="horizontal" open={isSidebarOpen}>
      <NavContainer
        border={{ side: 'right', color: 'light-4' }}
        // margin={{ top: 'large' }}
        responsive
        isCollapsed={isCollapsed}
        background={{ color: 'white' }}
      >
        <Box ref={ref}>{NavItems}</Box>
      </NavContainer>
    </Collapsible>
  )
}

export default LeftNav
