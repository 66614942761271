import React from 'react'
import { Box } from 'grommet'
import { addDoc, collection } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { db } from '../../../firebase'
import AutoForm from '../autoform/AutoForm'
import { selectSignedInRole, selectSignedInVendor, selectVendors } from '../../../state/selectors/firestore'
import { Vendor } from '../../../lib/firestore/types/types'
import { paymentMethodFormSchema, initialPaymentMethodValues } from './constants'
import joi from '../../../lib/firestore/joi'
import { mapPaymentMethodFormFieldsToFirestoreFields } from './utils'
import { Collection } from '../../../lib/firestore/collections'

function NewPaymentMethodForm() {
  const navigate = useNavigate()
  const role = useSelector(selectSignedInRole)
  const vendors: Vendor[] = useSelector(selectVendors)
  const signedInVendor = useSelector(selectSignedInVendor)

  const initialVendor = signedInVendor

  const vendorBrandNames = vendors.map((vendor: any) => vendor.brandName)

  const formSchema = [
    {
      id: 'vendorName',
      name: 'Vendor',
      type: 'select',
      options: vendorBrandNames,
      required: true,
      disabled: role === 'vendor',
    },
    ...paymentMethodFormSchema,
  ]

  const onSubmit = async (values: any) => {
    const fields = mapPaymentMethodFormFieldsToFirestoreFields(values, vendors)
    const result = joi.paymentMethod.validate(fields)
    if (result.error) {
      throw new Error(result.error.message)
    } else {
      console.log(result)
    }
    const ref = await addDoc(collection(db, Collection.paymentMethods), fields)
    navigate(`/paymentMethods/${ref.id}`)
  }

  return (
    <Box>
      <AutoForm
        readOnlyMode={false}
        initialValues={{
          ...initialPaymentMethodValues,
          vendorName: initialVendor?.brandName,
        }}
        formSchema={formSchema}
        onSubmit={onSubmit}
        onCancel={() => navigate('/paymentMethods')}
      />
    </Box>
  )
}

export default NewPaymentMethodForm
