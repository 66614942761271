import _ from 'lodash'
import { Box } from 'grommet'
import React from 'react'
import { ReadOnlyBox, ReadOnlyTitle, SubFieldsContainer } from '../ViewComponents'
import AutoFormField from '../AutoFormField'
import { renderField } from '../fieldRenderer'

function CompoundField({
  id,
  name,
  formState,
  setFormState,
  readOnlyMode,
  required,
  help,
  validate,
  disabled,
  width,
  errors,
  setFormErrors,
  fields,
  validators,
}: any) {
  const nameText = _.isFunction(name) ? name(formState, id) : name

  if (readOnlyMode) {
    return (
      <ReadOnlyBox key={id} width="100%">
        <ReadOnlyTitle>{nameText}</ReadOnlyTitle>
        <Box direction="row-responsive">
          {fields.map((field) => {
            const subField = {
              ...field,
              id: `${id}.${field.id}`,
            }
            return renderField(subField, formState, setFormState, readOnlyMode, errors, setFormErrors, validators)
          })}
        </Box>
      </ReadOnlyBox>
    )
  }

  return (
    <AutoFormField
      id={id}
      key={id}
      required={required}
      help={help}
      validate={validate}
      disabled={disabled}
      width={width}
    >
      <SubFieldsContainer direction="row">
        {nameText}
        {fields.map((field: any) => {
          const fieldWidth = field.width || `${Math.floor(100 / fields.length) - 1}%`
          const subField = {
            ...field,
            id: `${id}.${field.id}`,
            width: fieldWidth,
          }
          return renderField(subField, formState, setFormState, readOnlyMode, errors, setFormErrors, validators)
        })}
      </SubFieldsContainer>
    </AutoFormField>
  )
}

export default CompoundField
