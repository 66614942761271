import React from 'react'
import EditPaymentMethodForm from '../../forms/paymentMethod/EditPaymentMethodForm'
import PageTitle from '../../utils/PageTitle'
import PageCard from '../../utils/PageCard'

function EditPaymentMethod() {
  return (
    <PageCard>
      <PageTitle>Edit Payment Method</PageTitle>
      <EditPaymentMethodForm />
    </PageCard>
  )
}

export default EditPaymentMethod
