import React from 'react'
import EditShippingProfileForm from '../../forms/shippingProfile/EditShippingProfileForm'
import PageTitle from '../../utils/PageTitle'
import PageCard from '../../utils/PageCard'

function EditShippingProfile() {
  return (
    <PageCard>
      <PageTitle>Edit Shipping Profile</PageTitle>
      <EditShippingProfileForm />
    </PageCard>
  )
}

export default EditShippingProfile
