import React from 'react'
import NewVendorAccountForm from '../../../forms/vendorAccount/NewVendorAccountForm'
import PageTitle from '../../../utils/PageTitle'
import PageCard from '../../../utils/PageCard'

function NewVendorAccount() {
  return (
    <PageCard>
      <PageTitle>New Vendor</PageTitle>
      <NewVendorAccountForm />
    </PageCard>
  )
}

export default NewVendorAccount
